html {
    height: 100%;
}

body {
    font-size: 16px;
    height: 100%;
    outline: none;
    font-family: "Arial", sans-serif !important;
    scroll-behavior: smooth;
    overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    font-weight: 700;
}

img {
    max-width: 100%;
}

a:hover {
    text-decoration: none;
    outline: 0;
}

a:focus {
    text-decoration: none;
    outline: 0;
}


.comming-soon {
    background: url(../../../public/images/5205447.jpg) no-repeat center center;
    background-size: cover;
    background-size: cover;
    width: 100%;
    position: relative;
    min-height: 100%;
    overflow-x: hidden;
}

.comming-soon:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgb(0 0 0 / 47%);
}


.comming-soon-info {
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 2;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 1;
    height: 100vh;
}

.comming-soon-inner {
    width: 100%;
    padding: 0px;
    text-align: center;
}

.comming-soon-inner .logo {
    margin: 30px 0;
    margin-top: 20px;
    padding-top: 20px;
    margin-bottom: 20px;
}


.buyticketsbtndiv .addtocardbtnicon {
    display: flex;
    margin-top: 40px;
}

.addtocardbtnicon .buyticketbtn {
    border: none;
    background: #009e49;
    padding: 10px 20px;
    outline: none;
    box-shadow: 0px 2px 4px 0px #00000040;
    margin: 0 auto;
    border-radius: 10px;
    color: #fff;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.4px;
    width: auto;
}

.not-found-text h4 {
    color: #fff;
    text-transform: uppercase;
    font-size: 16px;

}

.not-found-text h2 {
    color: #fff;
    text-transform: uppercase;
    font-size: 35px;
    margin: 40px 0 20px 0;
}

.comming-soon.not-found .logo img {
    width: 400px;
}

@media screen and (max-width: 767px) {

    .comming-soon.not-found .logo img {
        width: 300px;
    }

    .not-found-text h2 {
        font-size: 30px;
    }




}