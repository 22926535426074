.loader {
  width: 50px;
  /* margin-right: 20px; */
  margin-left: 620px;
  aspect-ratio: 1;
  display: grid;
  border: 4px solid #0000;
  border-radius: 50%;
  border-right-color: #25b09b;
  animation: l15 1s infinite linear;
  margin: 0 auto;
}

.loader::before,
.loader::after {
  content: "";
  grid-area: 1/1;
  margin: 2px;
  border: inherit;
  border-radius: 50%;
  animation: l15 2s infinite;
}

.loader::after {
  margin: 8px;
  animation-duration: 3s;
}

@keyframes l15 {
  100% {
    transform: rotate(1turn)
  }
}

.maincont_section_load {
  padding-bottom: 40px;
  margin-top: -1px;
  height: 100%;
  width: 100%;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  z-index: 111;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #00000042;
}